@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,800&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  background-color: #f2f2f2;
}

* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}
